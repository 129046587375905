define("discourse/plugins/discourse-adplugin/discourse/components/house-ads-list-setting", ["exports", "@ember/component", "@ember/object/computed", "discourse/plugins/discourse-adplugin/discourse/components/house-ads-setting", "@ember/template-factory"], function (_exports, _component, _computed, _houseAdsSetting, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label for={{this.name}}>{{this.title}}</label>
  {{house-ads-chooser
    settingValue=this.adValue
    choices=this.adNames
    onChange=(action (mut this.adValue))
  }}
  <div class="setting-controls">
    {{#if this.changed}}
      <DButton class="ok" @action={{action "save"}} @icon="check" />
      <DButton class="cancel" @action={{action "cancel"}} @icon="times" />
    {{/if}}
  </div>
  <p class="help">{{this.help}}</p>
  */
  {
    "id": "DFq6avk3",
    "block": "[[[10,\"label\"],[15,\"for\",[30,0,[\"name\"]]],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"],[1,[28,[35,0],null,[[\"settingValue\",\"choices\",\"onChange\"],[[30,0,[\"adValue\"]],[30,0,[\"adNames\"]],[28,[37,1],[[30,0],[28,[37,2],[[30,0,[\"adValue\"]]],null]],null]]]]],[1,\"\\n\"],[10,0],[14,0,\"setting-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"changed\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"ok\"]],[[\"@action\",\"@icon\"],[[28,[37,1],[[30,0],\"save\"],null],\"check\"]],null],[1,\"\\n    \"],[8,[39,4],[[24,0,\"cancel\"]],[[\"@action\",\"@icon\"],[[28,[37,1],[[30,0],\"cancel\"],null],\"times\"]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,2],[14,0,\"help\"],[12],[1,[30,0,[\"help\"]]],[13]],[],false,[\"house-ads-chooser\",\"action\",\"mut\",\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-adplugin/discourse/components/house-ads-list-setting.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _houseAdsSetting.default.extend({
    classNames: "house-ads-setting house-ads-list-setting",
    adNames: (0, _computed.mapBy)("allAds", "name")
  }));
});